import React, { useEffect, useState } from 'react'
// import { Grid, GridItem } from "@chakra-ui/react";
import Sidebar from '../Sidebar'
import Stepper from '../../Stepper/Stepper'
import Question from '../Question'
import { Center , HStack, VStack, Stack, Flex, Text, Button } from '@chakra-ui/react'
import { ChevronRightIcon } from '@chakra-ui/icons'
import { Box, useMediaQuery } from '@chakra-ui/react'
import { SimpleGrid } from '@chakra-ui/react'
import { Texts, LanguageContext } from '../../Language'

const textStyle = {
  fontFamily: 'Source Sans Pro, sans-serif',
}
const questions = [
  {
    id: '1',
    question: <Texts tid="GF1" />,
  },
  {
    id: '2',
    question: <Texts tid="GF2" />,
  },
  {
    id: '3',
    question: <Texts tid="GF3" />,
  },
  {
    id: '4',
    question: <Texts tid="GF4" />,
  },
  {
    id: '5',
    question: <Texts tid="GF5" />,
  },
]

export function General(props) {
  const { onButtonClick, setFinalAnswers } = props

  const [theAnswers, setTheAnswers] = useState({})

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  function setAnswer(question, answer) {
    const temp = { ...theAnswers }
    temp[question.id] = getTextById(answer)
    setTheAnswers(temp)
  }

  function goNext() {
    // console.log("here are the answers", theAnswers);
    setFinalAnswers('general_feedback', theAnswers)
    onButtonClick('pagetwo')
  }

  function getTextById(id) {
    if (id === 1) {
      return 1
    } else if (id === 2) {
      return 2
    } else if (id === 3) {
      return 3
    } else if (id === 4) {
      return 4
    } else if (id === 0) {
      return 0
    }
  }

  const [isLargerThanMobile] = useMediaQuery('(min-width: 768px)')

  return (
    <div style={{ display: 'grid', gridTemplateColumns:  '10vw 90vw', gap: '0px' }}>
      <div > 
        
          <Box  maxContentWidth="fit-content"> 
            {isLargerThanMobile && (
              <Sidebar
                onButtonClick={onButtonClick}
                pathname={'GeneralFeedback'}
              />
            )}
          </Box>
          </div>

        <div >
          <SimpleGrid  width='auto' >
          <Stack bgGradient="linear(to-r, #E31937, #5236AB)" height={'100%'} >
          <div>
            <Box style={{
                  ...textStyle,
                  fontSize: "18px",
                  fontWeight: "600",
                  color: "white",
                  textAlign: "center", 
                  marginTop: "2%"
                }}
            >
              <Texts tid="Gen1" />

            </Box>
              <Box
                style={textStyle}
                    fontSize="16px"
                    fontWeight={'600'}                   
                    textAlign="center"                                       
                    color={'gray.300'}
              >
                <Texts tid="Gen2" />
              </Box>
              <Box
                style={textStyle}
                    fontSize="16px"
                    fontWeight={'600'}                   
                    textAlign="center"                                       
                    color={'gray.300'}
                    marginBottom={'2%'}
              >
                <Texts tid="Gen3" />
              </Box>
                  <Stack style={textStyle} padding="5" fontSize="16px" spacing="10" >
                    {questions.map(q => (
   
                        <Question question={q} setAnswer={setAnswer} />

                    ))}
                  </Stack>
            




          </div> 

          
          <Flex
              bottom="0"
              width="100%"
              px="5vw"
              border="1px"
              borderColor="rgba(0, 0, 0, 0)"
              py="10px"
              justify={'space-between'}
              bg="rgba(0, 0, 0, 0)"
              verticalAlign={'center'}
            >
              <Button
                rightIcon={<ChevronRightIcon />}
                colorScheme="teal"
                onClick={() => goNext()}
                variant="solid"
                borderRadius={'16px'}
                bgColor={'#9E83F5'}
                style={textStyle}
              >
                <Texts tid="ButtonNext" />
              </Button>
              {/* <Button py="auto" colorScheme={"blue"} onClick={() => goNext()} variant='ghost'>
              Next 
            </Button> */}
            </Flex>
        </Stack >
        </SimpleGrid>
      </div >
    
  </div>
  )
}

function FeedbackWizard(props) {
  return <Stepper />
}

export default FeedbackWizard
