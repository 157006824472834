// import { ChevronRightIcon } from "@chakra-ui/icons";

import { Flex, Stack, Text } from "@chakra-ui/react";

import { Texts, LanguageContext } from "../Language";

const textStyle = {
  fontFamily: "Source Sans Pro, sans-serif",
  fontSize: "18px",
};

function Sidebar(props: any) {
  const { pathname, onButtonClick } = props;

  function checkIsActive(path: string): boolean {
    if (pathname === `${path}`) return true;
    else return false;
  }

  return (
    <>
      <Stack
        position="absolute"
        left={0}
        width="15vw"
        height="95%"
        spacing="0"
        backgroundColor={"white"}
      >
        <Flex
          w="100%"
          pl="3px"
          h="67px"
          align="center"
          style={textStyle}
          justify="center"
          bg={checkIsActive("GeneralFeedback") ? "#CBC3E6" : ""}
          cursor="pointer"
          borderLeft={checkIsActive("GeneralFeedback") ? "4px solid" : ""}
          borderLeftColor={checkIsActive("GeneralFeedback") ? "#CBC3E6" : ""}
          _hover={{
            bg: checkIsActive("GeneralFeedback") ? "" : "#CBC3E6",
            borderLeft: checkIsActive("GeneralFeedback") ? "" : "4px solid",
            borderLeftColor: checkIsActive("GeneralFeedback") ? "" : "#5236AB",
            pl: checkIsActive("GeneralFeedback") ? "" : "0px",
          }}
          onClick={() => onButtonClick("pageone")}
        >
          <Text>
            <Texts tid="Sidebar1" />
          </Text>

          {/*{checkIsActive("GeneralFeedback") ? <ChevronRightIcon boxSize={5} /> : ""} */}
        </Flex>

        <Flex
          w="100%"
          pl="3px"
          h="67px"
          align="center"
          justify="center"
          style={textStyle}
          bg={checkIsActive("SpecificFeedback") ? "#CBC3E6" : ""}
          cursor="pointer"
          borderLeft={checkIsActive("SpecificFeedback") ? "4px solid" : ""}
          borderLeftColor={checkIsActive("SpecificFeedback") ? "#CBC3E6" : ""}
          _hover={{
            bg: checkIsActive("SpecificFeedback") ? "" : "#CBC3E6",
            borderLeft: checkIsActive("SpecificFeedback") ? "" : "4px solid",
            borderLeftColor: checkIsActive("SpecificFeedback") ? "" : "#5236AB",
            pl: checkIsActive("SpecificFeedback") ? "" : "0px",
          }}
          onClick={() => onButtonClick("pagetwo")}
        >
          <Text>
            <Texts tid="Sidebar2" />
          </Text>
          {/*{checkIsActive("SpecificFeedback") ? <ChevronRightIcon boxSize={5} /> : ""}*/}
        </Flex>

        <Flex
          w="100%"
          pl="3px"
          h="67px"
          align="center"
          justify="center"
          style={textStyle}
          bg={checkIsActive("FurtherDetail") ? "#CBC3E6" : ""}
          cursor="pointer"
          borderLeft={checkIsActive("FurtherDetail") ? "4px solid" : ""}
          borderLeftColor={checkIsActive("FurtherDetail") ? "#CBC3E6" : ""}
          _hover={{
            bg: checkIsActive("FurtherDetail") ? "" : "#CBC3E6",
            borderLeft: checkIsActive("FurtherDetail") ? "" : "4px solid",
            borderLeftColor: checkIsActive("FurtherDetail") ? "" : "#5236AB",
            pl: checkIsActive("FurtherDetail") ? "" : "0px",
          }}
          onClick={() => onButtonClick("pagethree")}
        >
          <Text>
            <Texts tid="Sidebar3" />
          </Text>

          {/* {checkIsActive("FurtherDetails") ? <ChevronRightIcon boxSize={5} /> : ""}*/}
        </Flex>
      </Stack>
    </>
  );
}

export default Sidebar;
