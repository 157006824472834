import {
  Button,
  Flex,
  Stack,
  StackDivider,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react'
import { Modal, ModalContent, ModalBody } from '@chakra-ui/react'
import {
  CheckCircleIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  WarningIcon,
} from '@chakra-ui/icons'
import { SimpleGrid } from '@chakra-ui/react'

import { Radio, RadioGroup } from '@chakra-ui/react'
import { useState, useEffect } from 'react'
import Sidebar from '../Sidebar'
import { useNavigate } from 'react-router-dom'
import { useMediaQuery } from '@chakra-ui/react'
import { Texts, LanguageContext } from '../../Language'
import { useMsal, useMsalAuthentication } from '@azure/msal-react'
import { InteractionType } from '@azure/msal-browser'

const textStyle = {
  fontFamily: 'Source Sans Pro, sans-serif',
}
const questions = [
  {
    label: <Texts tid="FD1" />,
    keyword: 'age',
    options: [
      {
        id: '1',
        label: '<35',
        value: '<35',
      },

      {
        id: '2',
        label: '35-50',
        value: '35-50',
      },

      {
        id: '3',
        label: '>50',
        value: '>50',
      },

      {
        id: '4',
        label: <Texts tid="FDA1" />,
        value: 'prefer not to say',
      },
    ],
  },
  {
    label: <Texts tid="FD2" />,
    keyword: 'gender',
    options: [
      {
        id: '1',
        label: <Texts tid="FDA2" />,
        value: 'male',
      },

      {
        id: '2',
        label: <Texts tid="FDA3" />,
        value: 'female',
      },

      {
        id: '3',
        label: <Texts tid="FDA4" />,
        value: 'non-binary',
      },

      {
        id: '4',
        label: <Texts tid="FDA1" />,
        value: 'prefer not to say',
      },
    ],
  },
]

function FurtherDetail(props) {
  const [error, setError] = useState(null)
  const [isErrorOpen, setIsErrorOpen] = useState(false)
  const [theAnswers, setTheAnswers] = useState({
    age: 'prefer not to say',
    gender: 'prefer not to say',
  })

  const { onButtonClick, setFinalAnswers } = props

  const { accounts } = useMsal()

  // Ensure the user is authenticated (redirect if not)
  useMsalAuthentication(InteractionType.Redirect)

  const userToken = accounts[0]?.accessToken || ''
  console.log(userToken) // Get the user token from MSAL
  const navigate = useNavigate()

  const { isOpen, onOpen, onClose } = useDisclosure()

  function setAnswers(question, answer) {
    let i = { ...theAnswers }

    i[question] = answer

    setTheAnswers(i)
  }

  const schema = {
    age: 'prefer not to say',
    gender: 'prefer not to say',
    general_feedback: {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
    },
    specific_feedback: {
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
      13: 0,
      14: 0,
      15: 0,
      16: 0,
      17: 0,
      18: 0,
      19: 0,
      20: 0,
      21: 0,
      22: 0,
      23: 0,
      24: 0,
      25: 0,
      26: 0,
    },
  }

  function matchSchemaWithPostData(schema, postData) {
    for (const key in schema) {
      if (typeof schema[key] === 'object') {
        if (!postData.hasOwnProperty(key)) {
          postData[key] = {}
        }
        matchSchemaWithPostData(schema[key], postData[key])
      } else {
        if (!postData.hasOwnProperty(key)) {
          postData[key] = 0
        }
      }
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const submitClick = async () => {
    // Combine theAnswers state and answers prop into a single object
    const postData = {
      ...theAnswers,
      ...props.answers,
      feedback: props.feedback,
    }

    console.log(props.feedback)
    matchSchemaWithPostData(schema, postData)

    // Set the final answer with the postData object
    setFinalAnswers('further_details', postData)

    try {
      const response = await fetch('/api/surveyData', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // 'Authorization': `Bearer ${userToken}`, // Add the user token to the headers here
          'x-api-key': process.env.REACT_APP_API_KEY, // Add the API key to the headers here
        },
        body: JSON.stringify(postData),
      })

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`)
      }

      console.log(response)
    } catch (error) {
      console.error(error)
      setError(error)
      setIsErrorOpen(true)
    }

    // Show the confirmation modal and navigate to the home page
    onOpen()
    setTimeout(function () {
      onClose()
      navigate('/')
    }, 5000)
  }

  const [isLargerThanMobile] = useMediaQuery('(min-width: 768px)')

  return (
    <>
      <div style={{ overflowX: 'auto' }}>
        <SimpleGrid
          columns={{ sm: 1 }}
          spacing="6"
          textAlign="center"
          align="flex-start"
          rounded="lg"
          autoFlow="row"
        >
          <Flex direction="row" style={{ justifyContent: 'flex-end' }}>
            <div style={{ display: 'flex' }}>
              {isLargerThanMobile && (
                <Sidebar
                  onButtonClick={onButtonClick}
                  pathname={'FurtherDetail'}
                />
              )}
            </div>
            <Stack
              flexGrow={1}
              minH="100vh"
              maxW="85vw"
              align="center"
              bgGradient="linear(to-r, #E31937, #5236AB)"
              justify={'center'}
            >
              <Text
                textAlign={'center'}
                fontWeight="bold"
                paddingBottom={'30px'}
                style={textStyle}
                fontSize="18px"
                fontWeight={'600'}
                color={'white'}
              >
                <Texts tid="Fur1" />
                <br />
                <Text
                  textAlign={'center'}
                  fontSize="16px"
                  color={'gray.300'}
                  as="span"
                >
                  <Texts tid="Fur2" />
                </Text>
              </Text>
              <div>
                <Stack
                  style={textStyle}
                  fontWeight={'600'}
                  fontSize="16px"
                  px="5vw"
                  my="2vw"
                  py="20px"
                ></Stack>
                <VStack
                  divider={<StackDivider borderColor="gray.200" />}
                  spacing={4}
                  align="stretch"
                >
                  {questions.map(question => (
                    <FurtherDetailsQuestion
                      style={textStyle}
                      fontSize="16px"
                      question={question}
                      setAnswers={setAnswers}
                    />
                  ))}
                </VStack>
              </div>

              <Flex
                marginTop={'200px'}
                px="5vw"
                w="100%"
                border="1px"
                borderColor="rgba(0, 0, 0, 0)"
                py="10px"
                justify={'space-between'}
                bg="rgba(0, 0, 0, 0)"
                verticalAlign={'center'}
              >
                <Button
                  leftIcon={<ChevronLeftIcon />}
                  colorScheme="teal"
                  onClick={() => onButtonClick('pagetwo')}
                  variant="solid"
                  borderRadius={'16px'}
                  bgColor={'#9E83F5'}
                  style={textStyle}
                >
                  <Texts tid="ButtonPrev" />
                </Button>

                <Button
                  rightIcon={<ChevronRightIcon />}
                  colorScheme="teal"
                  onClick={submitClick}
                  variant="solid"
                  borderRadius={'16px'}
                  bgColor={'#9E83F5'}
                  style={textStyle}
                >
                  <Texts tid="ButtonSubmit" />
                </Button>
              </Flex>
            </Stack>
          </Flex>{' '}
        </SimpleGrid>{' '}
      </div>

      <Modal rounded="20" size="2xl" p="0" isOpen={isOpen} onClose={onClose}>
        <ModalContent p="0">
          <ModalBody p="0">
            <Stack height="40vh">
              <Stack
                align="center"
                bg="green.400"
                justify="center"
                w="100%"
                h="50%"
              >
                <CheckCircleIcon boxSize={20} color="white" />
              </Stack>

              <Stack align="center" justify="center" p="20px">
                <Text fontSize="15px" fontWeight="700">
                  Thank you for helping us make in this journey to a better DE&I
                  culture!
                </Text>

                <Text
                  py="20px"
                  fontSize="12px"
                  fontWeight="600"
                  align="center"
                  color="gray.400"
                >
                  Your responses will help us to increase transparency and
                  encourage initiatives and actions towards a more diverse and
                  equitable community.
                </Text>

                <Button
                  fontSize="10px"
                  mt="20"
                  variant="outline"
                  height="25px"
                  color="purple"
                  rounded="50"
                  borderColor="purple"
                  onClick={() => navigate('/')}
                >
                  Return to Homepage
                </Button>
              </Stack>
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>

      <Modal
        rounded="20"
        size="2xl"
        p="0"
        isOpen={isErrorOpen}
        onClose={() => setIsErrorOpen(false)}
      >
        <ModalContent p="0">
          <ModalBody p="0">
            <Stack height="40vh">
              <Stack
                align="center"
                bg="red.400"
                justify="center"
                w="100%"
                h="50%"
              >
                <WarningIcon boxSize={20} color="white" />
              </Stack>

              <Stack align="center" justify="center" p="20px">
                <Text fontSize="15px" fontWeight="700">
                  Sorry, an error occurred while submitting your response.
                </Text>

                <Text
                  py="20px"
                  fontSize="12px"
                  fontWeight="600"
                  align="center"
                  color="gray.400"
                >
                  Please try again later.
                </Text>

                <Button
                  fontSize="10px"
                  mt="20"
                  variant="outline"
                  height="25px"
                  color="purple"
                  rounded="50"
                  borderColor="purple"
                  onClick={() => navigate('/')}
                >
                  Return to Homepage
                </Button>
              </Stack>
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}

function FurtherDetailsQuestion(props) {
  const { question, setAnswers } = props

  function setValue(e) {
    setAnswers(question.keyword, e)
  }

  return (
    <Stack spacing="10" align={'center'}>
      <Stack
        py="15px"
        px="20px"
        w="400px"
        h="239px"
        bg="white"
        boxShadow={'lg'}
        borderLeft="3px solid"
        borderLeftColor={'#5236AB'}
      >
        <Text fontSize={'14px'}>{question.label}</Text>

        <Text fontSize={'12px'} color="gray.300">
          <Texts tid="FD3" />
        </Text>

        <RadioGroup onChange={setValue}>
          <Stack spacing={'15px'}>
            {question.options.map(option => (
              <Radio id={option.id} value={option.value}>
                {/* //added this onchange and pass label value (Question) and option which contains the value (answer) */}

                <Text pl="10px" fontWeight={'bold'} fontSize={'12px'}>
                  {option.label}
                </Text>
              </Radio>
            ))}
          </Stack>
        </RadioGroup>
      </Stack>
    </Stack>
  )
}

export default FurtherDetail
