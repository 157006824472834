import './MyCss.css'
import React, { useState } from 'react'
import MultiStepProgressBar from './components/MultiStepProgressBar/MultiStepProgressBar'
import { General } from '../Survey/Steps/FeedbackWizard'
import SpecificFeedback from '../Survey/Steps/SpecificFeedback'
import FurtherDetail from '../Survey/Steps/FurtherDetail'
import { Box, useSafeLayoutEffect } from '@chakra-ui/react'

function Stepper() {
  const [answers, setAnswers] = useState({})
  const [feedbacks, setFeedbacks] = useState({})
  const setFinalFeedbacks = feedbacks => {
    setFeedbacks(feedbacks)
  }

  function setFinalAnswers(name, value) {
    let i = { ...answers }

    i[name] = value
    setAnswers(i)
  }

  const [page, setPage] = useState('pageone')

  const nextPage = page => {
    setPage(page)
  }

  const nextPageNumber = pageNumber => {
    switch (pageNumber) {
      case '1':
        setPage('pageone')
        break
      case '2':
        setPage('pagetwo')
        break
      case '3':
        setPage('pagethree')
        break
      default:
        setPage('1')
    }
  }

  return (
    <Box bg="#E1E1E1" boxShadow={'lg'}>
      <div>
        <MultiStepProgressBar page={page} onPageNumberClick={nextPageNumber} />
        {
          {
            pageone: (
              <General
                onButtonClick={nextPage}
                setFinalAnswers={setFinalAnswers}
              />
            ),
            pagetwo: (
              <SpecificFeedback
                onButtonClick={nextPage}
                setFinalAnswers={setFinalAnswers}
                setFinalFeedbacks={setFinalFeedbacks}
              />
            ),
            pagethree: (
              <FurtherDetail
                onButtonClick={nextPage}
                setFinalAnswers={setFinalAnswers}
                answers={answers}
                feedbacks={feedbacks}
              />
            ),
            pageOneTitle: 'General Feedback',
            pageTwoTitle: 'Specific Feedback',
            pageThreeTitle: 'Further Details',
          }[page]
        }
      </div>
    </Box>
  )
}

export default Stepper
