import {
  Stack,
  Text,
  Heading,
  Box,
  Card,
  CardBody,
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  SimpleGrid,
  Button,
  CardHeader,
} from "@chakra-ui/react";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ChevronRightIcon } from "@chakra-ui/icons";

import { Texts, LanguageContext } from "../Language";

function Landing() {
  const navigate = useNavigate();

  const navigateToGeneralFeeback = () => {
    navigate("/feedback-wizard");
  };
  const textStyle = {
    fontFamily: "Source Sans Pro, sans-serif",
  };

  return (
    <React.Fragment>
      <SimpleGrid
        columns={{ sm: 1 }}
        spacing="8"
        p="10"
        textAlign="center"
        rounded="lg"
        style={textStyle}
      >
        <Stack px="5vw">
          <Heading
            style={textStyle}
            textAlign={"left"}
            as="h1"
            color="#5236AB"
            fontSize="38px"
            fontWeight={"600"}
          >
            <Texts tid="Header" />
          </Heading>
          <Text
            style={textStyle}
            fontSize="18px"
            fontWeight={"400"}
            textAlign={"justify"}
          >
            <Texts tid="Description" /> <br />
            <Texts tid="Description2" />
          </Text>
        </Stack>
      </SimpleGrid>

      <SimpleGrid
        columns={{ sm: 1 }}
        spacing="8"
        textAlign="left"
        rounded="lg"
        marginLeft={"70px"}
        marginRight={"70px"}
      >
        <Stack padding={"10"} spacing={6}>
          <Box>
            <Button
              textAlign={"left"}
              rightIcon={<ChevronRightIcon />}
              onClick={navigateToGeneralFeeback}
              variant="solid"
              color="#FFFFFF"
              bgGradient="linear(to-r, #E31937, #5236AB)"
              size="md"
              width="300px"
              style={textStyle}
              fontSize="16px"
              fontWeight={"500"}
            >
              <Texts tid="Start" />
            </Button>
          </Box>
        </Stack>
      </SimpleGrid>
      <SimpleGrid
        bg="gray.50"
        columns={{ md: 3 }}
        spacing="8"
        textAlign="center"
        rounded="lg"
        color="gray.400"
        marginLeft={"70px"}
        marginRight={"70px"}
        width={"auto"}
      >
        <Card height={"auto"}>
          <CardHeader bg={"#5236AB"}>
            <Heading
              style={textStyle}
              fontSize="20px"
              fontWeight={"500"}
              textColor={"white"}
              textAlign={"left"}
              size="sm"
            >
              <Texts tid="Card1" />
            </Heading>
          </CardHeader>
          <CardBody>
            <Text
              style={textStyle}
              fontSize="16px"
              fontWeight={"400"}
              textAlign={"left"}
              size="sm"
            >
              <Texts tid="Card1text" />
            </Text>
          </CardBody>
        </Card>
        <Card height={"auto"}>
          <CardHeader bg={"#5236AB"}>
            <Heading
              style={textStyle}
              fontSize="20px"
              fontWeight={"500"}
              textColor={"white"}
              textAlign={"left"}
              size="sm"
            >
              <Texts tid="Card2" />
            </Heading>
          </CardHeader>
          <CardBody>
            <Text
              style={textStyle}
              fontSize="16px"
              fontWeight={"400"}
              textAlign={"left"}
              size="sm"
            >
              <Texts tid="Card2text" />
            </Text>
          </CardBody>
        </Card>
        <Card height={"auto"}>
          <CardHeader bg={"#5236AB"}>
            <Heading
              style={textStyle}
              fontSize="20px"
              fontWeight={"500"}
              textColor={"white"}
              textAlign={"left"}
              size="sm"
            >
              <Texts tid="Card3" />
            </Heading>
          </CardHeader>
          <CardBody>
            <Text
              style={textStyle}
              fontSize="16px"
              fontWeight={"400"}
              textAlign={"left"}
              size="sm"
            >
              <Texts tid="Card3text" />
            </Text>
          </CardBody>
        </Card>
      </SimpleGrid>
    </React.Fragment>
  );
}

export default Landing;
