import React from "react";
import "./MultiStepProgressBar.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import { Button, Flex} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { Texts, LanguageContext } from '../../../Language';

const textStyle = {
  fontFamily: 'Source Sans Pro, sans-serif',
};
const MultiStepProgressBar = ({
  page,
  onPageNumberClick,
}) => {
  var stepPercentage = 0;
  if (page === "pageone") {
    stepPercentage = 0;
  } else if (page === "pagetwo") {
    stepPercentage = 50;
  } else if (page === "pagethree") {
    stepPercentage = 100;
  } else {
    stepPercentage = 0;
  }
  
  const navigate = useNavigate();

  return (
    <Flex bg="white" py="5px" border="1px" borderColor="gray.200">
      <ProgressBar percent={stepPercentage}>
        <Step>
          {({ accomplished, index }) => (
            <>
              <div
                className={`indexedStep ${
                  accomplished ? "accomplished" : null
                }`}
                onClick={() => onPageNumberClick("1")}
              >
                {index + 1}
              </div>
            </>
          )}
        </Step>
        <Step>
          {({ accomplished, index }) => (
            <div
              className={`indexedStep ${accomplished ? "accomplished" : null}`}
              onClick={() => onPageNumberClick("2")}
            >
              {index + 1}
            </div>
          )}
        </Step>
        <Step>
          {({ accomplished, index }) => (
            <div
              className={`indexedStep ${accomplished ? "accomplished" : null}`}
              onClick={() => onPageNumberClick("3")}
            >
              {index + 1}
            </div>
          )}
        </Step>


      </ProgressBar>
      <Button
            colorScheme="teal"
            onClick={() => navigate("/")} 
            variant='outline'
            color="#5236AB"
            mr="20px"
            rounded="20px"
            style={textStyle}
          >
          <Texts tid="ButtonCancel" />
          </Button>
    </Flex>
  );
};

export default MultiStepProgressBar;
