import React, { useEffect, useState } from 'react';
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from 'chart.js';
import { Radar } from 'react-chartjs-2';

// import { useState } from "react";
import { median } from 'mathjs';

//import Polar from "./RadarChart";

import   feedback  from './data.json'; 
// import { ChartOptions, ScaleChartOptions } from 'chart.js';
ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);


export default function Polar() {
  const [feedback, setFeedback] = useState([]);

  useEffect(() => {
    fetch("/api/surveyData")
      .then((response) => response.json())
      .then((data) => {
        setFeedback(data);
      });
  }, []);

  const calculateMedian = (values) => {
    return values.length > 0 ? median(values.flat()) : 0;
  };

  
  const generalFeedbackValues = feedback.map(item => Object.values(item.general_feedback));
  const genmedianValue = calculateMedian(generalFeedbackValues.flat()); 
  const genderValues = feedback.map(item => Object.values(item.specific_feedback).slice(0, 3));
  const gendermedianValue = calculateMedian(genderValues.flat());
  const soValues = feedback.map(item => Object.values(item.specific_feedback).slice(3, 6));
  const somedianValue = calculateMedian(soValues.flat());
  const religionValues = feedback.map(item => Object.values(item.specific_feedback).slice(6, 9));
  const religionmedianValue = calculateMedian(religionValues.flat());
  const ageValues = feedback.map(item => Object.values(item.specific_feedback).slice(9, 12));
  const agemedianValue = calculateMedian(ageValues.flat());
  const pmabilitiesValues = feedback.map(item => Object.values(item.specific_feedback).slice(12, 15));
  const pmabilitiesmedianValue = calculateMedian(pmabilitiesValues.flat());
  const sbgValues = feedback.map(item => Object.values(item.specific_feedback).slice(15, 18));
  const sbgmedianValue = calculateMedian(sbgValues.flat());
  const nationalityValues = feedback.map(item => Object.values(item.specific_feedback).slice(18, 21));
  const nationalitymedianValue = calculateMedian(nationalityValues.flat());

  //calculate the contributions- general
  const male_values = feedback.filter(item => item.gender === 'male').map(item => Object.values(item.general_feedback)).flat();  
  const female_values = feedback.filter(item => item.gender === 'female').map(item => Object.values(item.general_feedback)).flat();
  const count_male = male_values.filter(value => value === genmedianValue).length;
  const count_female = female_values.filter(value => value === genmedianValue).length;
  const total = count_male + count_female;
  const percentage_male = Math.round((count_male / total) * 100);
  const percentage_female = Math.round((count_female / total) * 100);

   //calculate the contributions- gender
  const gendermale_values = feedback.filter(item => item.gender === 'male').map(item => Object.values(item.specific_feedback).slice(0, 3)).flat(); 
  const genderfemale_values = feedback.filter(item => item.gender === 'female').map(item => Object.values(item.specific_feedback).slice(0, 3)).flat(); 
  const gendercount_male = gendermale_values.filter(value => value === gendermedianValue).length;
  const gendercount_female = genderfemale_values.filter(value => value === gendermedianValue).length;
  const gendertotal = gendercount_male + gendercount_female;
  const genderpercentage_male = Math.round((gendercount_male / gendertotal) * 100);
  const genderpercentage_female = Math.round((gendercount_female / gendertotal) * 100);

  //calculate the contributions- SexualOrientation
  const somale_values = feedback.filter(item => item.gender === 'male').map(item => Object.values(item.specific_feedback).slice(3, 6)).flat(); 
  const sofemale_values = feedback.filter(item => item.gender === 'female').map(item => Object.values(item.specific_feedback).slice(3, 6)).flat(); 
  const socount_male = somale_values.filter(value => value === somedianValue).length;
  const socount_female = sofemale_values.filter(value => value === somedianValue).length;
  const sototal = socount_male + socount_female;
  const sopercentage_male = Math.round((socount_male / sototal) * 100);
  const sopercentage_female = Math.round((socount_female / sototal) * 100);
 

  //calculate the contributions- religion
  const remale_values = feedback.filter(item => item.gender === 'male').map(item => Object.values(item.specific_feedback).slice(6, 9)).flat(); 
  const refemale_values = feedback.filter(item => item.gender === 'female').map(item => Object.values(item.specific_feedback).slice(6, 9)).flat(); 
  const recount_male = remale_values.filter(value => value === religionmedianValue).length;
  const recount_female = refemale_values.filter(value => value === religionmedianValue).length;
  const retotal = recount_male + recount_female;
  const repercentage_male = Math.round((recount_male / retotal) * 100);
  const repercentage_female = Math.round((recount_female / retotal) * 100);
 
  //calculate the contributions- age
  const agemale_values = feedback.filter(item => item.gender === 'male').map(item => Object.values(item.specific_feedback).slice(9, 12)).flat(); 
  const agefemale_values = feedback.filter(item => item.gender === 'female').map(item => Object.values(item.specific_feedback).slice(9, 12)).flat(); 
  const agecount_male = agemale_values.filter(value => value === agemedianValue).length;
  const agecount_female = agefemale_values.filter(value => value === agemedianValue).length;
  const agetotal = agecount_male + agecount_female;
  const agepercentage_male = Math.round((agecount_male / agetotal) * 100);
  const agepercentage_female = Math.round((agecount_female / agetotal) * 100);

  //calculate the contributions- physical mental abilites
  const pmamale_values = feedback.filter(item => item.gender === 'male').map(item => Object.values(item.specific_feedback).slice(12, 15)).flat(); 
  const pmafemale_values = feedback.filter(item => item.gender === 'female').map(item => Object.values(item.specific_feedback).slice(12, 15)).flat(); 
  const pmacount_male = pmamale_values.filter(value => value === pmabilitiesmedianValue).length;
  const pmacount_female = pmafemale_values.filter(value => value === pmabilitiesmedianValue).length;
  const pmatotal = pmacount_male + pmacount_female;
  const pmapercentage_male = Math.round((pmacount_male / pmatotal) * 100);
  const pmapercentage_female = Math.round((pmacount_female / pmatotal) * 100);
 
  //calculate the contributions- social background
  const sbgmale_values = feedback.filter(item => item.gender === 'male').map(item => Object.values(item.specific_feedback).slice(15, 18)).flat(); 
  const sbgfemale_values = feedback.filter(item => item.gender === 'female').map(item => Object.values(item.specific_feedback).slice(15, 18)).flat(); 
  const sbgcount_male = sbgmale_values.filter(value => value === sbgmedianValue).length;
  const sbgcount_female = sbgfemale_values.filter(value => value === sbgmedianValue).length;
  const sbgtotal = sbgcount_male + sbgcount_female;
  const sbgpercentage_male = Math.round((sbgcount_male / sbgtotal) * 100);
  const sbgpercentage_female = Math.round((sbgcount_female / sbgtotal) * 100);

  //calculate the contributions- nationality
  const natmale_values = feedback.filter(item => item.gender === 'male').map(item => Object.values(item.specific_feedback).slice(18, 21)).flat(); 
  const natfemale_values = feedback.filter(item => item.gender === 'female').map(item => Object.values(item.specific_feedback).slice(18, 21)).flat(); 
  const natcount_male = natmale_values.filter(value => value === nationalitymedianValue).length;
  const natcount_female = natfemale_values.filter(value => value === nationalitymedianValue).length;
  const nattotal = natcount_male + natcount_female;
  const natpercentage_male = Math.round((natcount_male / nattotal) * 100);
  const natpercentage_female = Math.round((natcount_female / nattotal) * 100);


  


  
 
//console.log(medianValue, gendermedianValue, somedianValue,religionmedianValue , agemedianValue, pmabilitiesmedianValue, sbgmedianValue, nationalitymedianValue)
  
 const data = {
  labels: ['Diversity General', 'Gender', 'Sexual Orientation', 'Religion', 'Age', 'Physical / MentalAbility', 'Social Background', 'Ethnicity / Nationality'],
  datasets: [
    {
      label: '% Male',
      data: [ genmedianValue, gendermedianValue, somedianValue,religionmedianValue , agemedianValue, pmabilitiesmedianValue, sbgmedianValue, nationalitymedianValue],
      backgroundColor: 'rgba(255, 99, 132, 0.2)',
      borderColor: 'rgba(255, 99, 132, 1)',
      borderWidth: 1,
    },
    {
      label: '% Female',
      data: [ genmedianValue, gendermedianValue, somedianValue,religionmedianValue , agemedianValue, pmabilitiesmedianValue, sbgmedianValue, nationalitymedianValue],
      backgroundColor: 'rgba(255, 99, 132, 0.2)',
      borderColor: 'rgba(255, 99, 132, 1)',
      borderWidth: 1,
    },
  ],
};



const options = {
  scales: {
    r: {
      min: 0,
      max: 4,
      ticks: {
        stepSize: 1,
      },
    },
  },
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: true,
      callbacks: {
        label: (context) => {
          const datasetLabel = context.dataset.label || '';
          const index = context.dataIndex || 0;
          const customValues = datasetLabel === '% Male' ? [percentage_male, genderpercentage_male,sopercentage_male,repercentage_male,agepercentage_male,pmapercentage_male,sbgpercentage_male,natpercentage_male ] : [percentage_female,genderpercentage_female, sopercentage_female,repercentage_female,agepercentage_female,pmapercentage_female,sbgpercentage_female, natpercentage_female,10];
          const customValue = customValues[index % customValues.length];
          return `${datasetLabel}: ${customValue}`;
        },
      },
      
    },
  },
};




  return(
  <div style={{ height: '60vh', width: '100vw', margin: '0 auto',display: 'flex', justifyContent: 'center'}} > 
   
   <Radar data={data} options={options}/>

   </div>
  );
}