
import en from './en.json';
import de from './de.json';

export const dictionaryList = { en,de };

export const languageOptions = {
  en: 'En',

  de: 'De'
};