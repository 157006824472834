import React from "react";
import data from "./test.json";
import { useState } from "react";
import { MinusIcon, AddIcon } from "@chakra-ui/icons";
import {
  Stack,
  Text,
  Box,
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  SimpleGrid,
} from "@chakra-ui/react";

import { Texts, LanguageContext } from "../Language";

function Faq() {
  const [shown, setShown] = useState(
    Array.from(Array(data.length)).map((elem) => false),
  );

  const toggleShow = (idx: number) => {
    setShown(shown.map((value, index) => (index === idx ? !value : value)));
  };
  const textStyle = {
    fontFamily: "Source Sans Pro, sans-serif",
  };

  return (
    <React.Fragment>
      <SimpleGrid
        columns={{ sm: 1 }}
        spacing="8"
        textAlign="center"
        rounded="lg"
        // marginLeft={"70px"}
        // marginRight={"70px"}
        width={"100%"}
      >
        <Stack spacing={6}>
          <Box>
            <Text
              bgColor={"#EFEFEF"}
              height={"150px"}
              p="10"
              style={textStyle}
              fontSize={"28px"}
              color={"#200A58"}
              textAlign="left"
            >
              <Texts tid="FAQheading" />
            </Text>
          </Box>
        </Stack>

        <Stack p="5" style={textStyle}>
          <Accordion allowToggle>
            <AccordionItem>
              {({ isExpanded }) => (
                <>
                  <h2>
                    <AccordionButton>
                      {isExpanded ? (
                        <MinusIcon fontSize="12px" />
                      ) : (
                        <AddIcon fontSize="12px" />
                      )}
                      <Box
                        as="span"
                        flex="1"
                        textAlign="left"
                        paddingLeft={"10px"}
                      >
                        <Texts tid="FAQ1" />
                      </Box>
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4} textAlign="left" paddingLeft={"37px"}>
                    <Texts tid="FAQ1A" />
                  </AccordionPanel>
                </>
              )}
            </AccordionItem>
            <AccordionItem>
              {({ isExpanded }) => (
                <>
                  <h2>
                    <AccordionButton>
                      {isExpanded ? (
                        <MinusIcon fontSize="12px" />
                      ) : (
                        <AddIcon fontSize="12px" />
                      )}
                      <Box
                        as="span"
                        flex="1"
                        textAlign="left"
                        paddingLeft={"10px"}
                      >
                        <Texts tid="FAQ2" />
                      </Box>
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4} textAlign="left" paddingLeft={"37px"}>
                    <Texts tid="FAQ2A" />
                  </AccordionPanel>
                </>
              )}
            </AccordionItem>
            <AccordionItem>
              {({ isExpanded }) => (
                <>
                  <h2>
                    <AccordionButton>
                      {isExpanded ? (
                        <MinusIcon fontSize="12px" />
                      ) : (
                        <AddIcon fontSize="12px" />
                      )}
                      <Box
                        as="span"
                        flex="1"
                        textAlign="left"
                        paddingLeft={"10px"}
                      >
                        <Texts tid="FAQ3" />
                      </Box>
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4} textAlign="left" paddingLeft={"37px"}>
                    <Texts tid="FAQ3A" />
                  </AccordionPanel>
                </>
              )}
            </AccordionItem>
            <AccordionItem>
              {({ isExpanded }) => (
                <>
                  <h2>
                    <AccordionButton>
                      {isExpanded ? (
                        <MinusIcon fontSize="12px" />
                      ) : (
                        <AddIcon fontSize="12px" />
                      )}
                      <Box
                        as="span"
                        flex="1"
                        textAlign="left"
                        paddingLeft={"10px"}
                      >
                        <Texts tid="FAQ4" />
                      </Box>
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4} textAlign="left" paddingLeft={"37px"}>
                    <Texts tid="FAQ4A" />
                  </AccordionPanel>
                </>
              )}
            </AccordionItem>
            <AccordionItem>
              {({ isExpanded }) => (
                <>
                  <h2>
                    <AccordionButton>
                      {isExpanded ? (
                        <MinusIcon fontSize="12px" />
                      ) : (
                        <AddIcon fontSize="12px" />
                      )}
                      <Box
                        as="span"
                        flex="1"
                        textAlign="left"
                        paddingLeft={"10px"}
                      >
                        <Texts tid="FAQ5" />
                      </Box>
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4} textAlign="left" paddingLeft={"37px"}>
                    <Texts tid="FAQ5A" />
                  </AccordionPanel>
                </>
              )}
            </AccordionItem>
            <AccordionItem>
              {({ isExpanded }) => (
                <>
                  <h2>
                    <AccordionButton>
                      {isExpanded ? (
                        <MinusIcon fontSize="12px" />
                      ) : (
                        <AddIcon fontSize="12px" />
                      )}
                      <Box
                        as="span"
                        flex="1"
                        textAlign="left"
                        paddingLeft={"10px"}
                      >
                        <Texts tid="FAQ6" />
                      </Box>
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4} textAlign="left" paddingLeft={"37px"}>
                    <Texts tid="FAQ6A" />
                  </AccordionPanel>
                </>
              )}
            </AccordionItem>
          </Accordion>
        </Stack>
      </SimpleGrid>
    </React.Fragment>
  );
}

export default Faq;
