import React from 'react'
import { useState, useEffect } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Landing from './Landing/Landing'
import Faq from './FAQ/Faq'
import SignInCheck from './SignInCheck'
import Bar from './Landing/Bar'
import FeedbackWizard from './Survey/Steps/FeedbackWizard'
import Stats from './Landing/Stats1'
import Dashboard from './Landing/Dashboard'
import { LanguageProvider } from './Language'

import { useMsal, useMsalAuthentication } from '@azure/msal-react'
import { InteractionType } from '@azure/msal-browser'

function App() {
  // useMsalAuthentication(InteractionType.Redirect)
  // const [m_strUser, setm_strUser] = useState<string>('')
  // function Render() {
  //   const { accounts } = useMsal()
  //   try {
  //     const username = accounts[0].username
  //     setm_strUser(username)
  //   } catch (e) {}
  // }
  // if (m_strUser != '')
  //   return (
  //     <div>
  //       <LanguageProvider>
  //         <BrowserRouter>
  //           {/* <SignInCheck /> */}
  //           <Bar />
  //           <Routes>
  //             <Route path="/" element={<Landing />} />
  //             <Route path="/feedback-wizard" element={<FeedbackWizard />} />
  //             <Route path="/faq" element={<Faq />} />
  //             {/* <Route path="/stats" element={<Stats />} /> */}
  //             <Route path="/dashboard" element={<Dashboard />} />
  //           </Routes>
  //         </BrowserRouter>
  //       </LanguageProvider>
  //     </div>
  //   )
  // else
  //   return (
  //     <>
  //       {Render()}
  //       <div>Please wait...</div>
  //     </>
  //   )
  return (
    <div>
      <LanguageProvider>
        <BrowserRouter>
          {/* <SignInCheck /> */}
          <Bar />
          <Routes>
            <Route path="/" element={<Landing />} />
            <Route path="/feedback-wizard" element={<FeedbackWizard />} />
            <Route path="/faq" element={<Faq />} />
            <Route path="/stats" element={<Stats />} />
            <Route path="/dashboard" element={<Dashboard />} />
          </Routes>
        </BrowserRouter>
      </LanguageProvider>
    </div>
  )
}

export default App
