import React from "react";
import Chart from "react-apexcharts";

const PieChart = ({ data, labels }) => {
  const options = {
    labels: labels,
    chart: { toolbar: { show: false } },
    legend: { position: "bottom" },
    responsive: [
      {
        breakpoint: 480,
        options: { legend: { position: "bottom" } },
      },
    ],
  };

  return <Chart options={options} series={data} type="pie" />;
};

export default PieChart;
