import { Flex, Stack, Text, Button } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { Grid, GridItem } from '@chakra-ui/react'

export default function Question(props) {
  const { question, setAnswer } = props

  const [currentSelected, setCurrentSelected] = useState()

  function getIsSelected(value) {
    if (value === currentSelected) {
      return true
    } else {
      return false
    }
  }

  function selectValue(answerId) {
    //console.log(
    //  `You have selected answer ${answerId} for question: ${question.question}`
    //);
    setCurrentSelected(answerId)
  }

  useEffect(() => {
    if (currentSelected !== null || currentSelected !== undefined) {
      setAnswer(question, currentSelected)
    }
  }, [currentSelected, question, setAnswer])

  return (
    <Stack
      px="10vw"
      align={'center'}
      justifyContent="center"
      key={question.id}
      height="64px"
    >
      <Grid
        minWidth="max-content"
        width="100%"
        boxShadow={'2xl'}
        templateColumns={['repeat(2, 1fr)', null, 'repeat(6, 1fr)']}
        gap="5"
        borderLeft={'2px'}
        borderColor="#5236AB"
        height={'100px'}
        alignContent="center"
        // align="center"
        justify="center"
        backgroundColor="white"
      >
        <GridItem maxW="376px" colSpan={3} h="12" px="18px">
          <Text
            padding={'5px'}
            margin={'auto'}
            h="100%"
            fontSize="14px"
            lineHeight={'13px'}
            fontWeight="600"
          >
            {question.question}
          </Text>
        </GridItem>
        <GridItem w="100%" colSpan={3} h="10">
          <Flex
            align="center"
            justify={{ base: 'space-evenly', md: 'space-between' }}
            spacing={{ base: 2, md: 0 }}
          >
            <QuestionButton
              answerId={1}
              label={1}
              selectValue={selectValue}
              isSelected={getIsSelected(1)}
              mb={{ base: '1rem', md: '0' }}
            />
            <QuestionButton
              answerId={2}
              label={2}
              selectValue={selectValue}
              isSelected={getIsSelected(2)}
              mb={{ base: '1rem', md: '0' }}
            />
            <QuestionButton
              answerId={3}
              label={3}
              selectValue={selectValue}
              isSelected={getIsSelected(3)}
              mb={{ base: '1rem', md: '0' }}
            />
            <QuestionButton
              answerId={4}
              label={4}
              selectValue={selectValue}
              isSelected={getIsSelected(4)}
              mb={{ base: '1rem', md: '0' }}
            />
            <QuestionButton
              answerId={0}
              label={0}
              selectValue={selectValue}
              isSelected={getIsSelected(0)}
              mb={{ base: '1rem', md: '0' }}
            />
          </Flex>
        </GridItem>
      </Grid>
    </Stack>
  )
}

function QuestionButton(props) {
  const { answerId, label, isSelected, selectValue: onSelectValue } = props
  return (
    <Stack
      fontSize={'13px'}
      fontWeight="700"
      color="gray.600"
      w="5vw"
      align="center"
    >
      <Button
        bg={isSelected === true ? '#5236AB' : 'white'}
        size="sm"
        color={isSelected === true ? 'white' : '#929293'}
        borderColor={isSelected === true ? '#5236AB' : '#929293'}
        variant={isSelected === true ? 'solid' : 'outline'}
        _hover={{ bg: '#5236AB' }}
        borderRadius="50"
        // variant="outline"
        onClick={() => onSelectValue(answerId)}
      >
        {label}
      </Button>
    </Stack>
  )
}
