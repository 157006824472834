import React, { useEffect, useState } from 'react';
import {
  // PolarChart,
  // CommonSeriesSettings,
  Series,
  // Tooltip,
  // Legend,
} from "devextreme-react/polar-chart";
import PieChart, {
  Label,
  Connector,
  Size,
} from "devextreme-react/pie-chart";
import Polar from "../Polar";

const calculateMedian = (values) => {
  values.sort((a, b) => a - b);
  const mid = Math.floor(values.length / 2);
  return values.length % 2 !== 0 ? values[mid] : (values[mid - 1] + values[mid]) / 2;
};

const preprocessData = (data) => {
  let genderCounts = {};
  let ageCounts = {};
  let points = {
    DiversityGeneral: [],
    Gender: [],
    SexualOrientation: [],
    Religion: [],
    Age: [],
    PhysicalMentalAbility: [],
    SocialBackground: [],
    EthnicityNationality: [],
  };

  data.forEach((entry) => {
    // Update gender and age counts for pie charts
    genderCounts[entry.gender] = (genderCounts[entry.gender] || 0) + 1;
    ageCounts[entry.age] = (ageCounts[entry.age] || 0) + 1;

    // Calculate medians for polar chart
  });

  const polarData = Object.entries(points).map(([key, values]) => ({
    argumentField: key,
    value: Math.round(calculateMedian(values)),
  }));
  const totalCount = Object.values(genderCounts).reduce((a, b) => a + b, 0);
  const totalAgeCount = Object.values(ageCounts).reduce((a, b) => a + b, 0);
  const pieData = {
    gender: Object.entries(genderCounts).map(([gender, count]) => ({ gender, percentage: Math.round((count / totalCount) * 100) })),
    age: Object.entries(ageCounts).map(([age, count]) => ({ age, percentage: Math.round((count / totalAgeCount) * 100) })),
  };
  
  return { polarData, pieData };
};

const Stats = () => {

  const [polarData, setPolarData] = useState([]);
  const [pieData, setPieData] = useState({});

  useEffect(() => {
    fetch("/api/surveyData")
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Error: " + response.status);
        }
      })
      .then(responseJson => {
        const { polarData, pieData } = preprocessData(responseJson);
        setPolarData(polarData);
        setPieData(pieData);
      })
      .catch(error => {
        console.error("Error fetching data: ", error);
      });
  }, []);

  if (!pieData.age || !pieData.gender) {
    return <div>Loading...</div>
  }



/* const customizeTooltip = (arg) => {
  return {
    text: arg.valueText
  };
}; */

  const genderData = pieData.gender.sort((a, b) => {
    if (a.gender === "male") return -1;
    if (a.gender === "female" && b.gender === "prefer not to say") return -1;
    if (a.gender === "female") return b.gender === "prefer not to say" ? 1 : -1;
    return 1;
  });

  const sortedData = pieData.age.sort((a, b) => {
    if (a.age === 'prefer not to say') return 1;
    if (b.age === 'prefer not to say') return -1;
    return parseInt(a.age) - parseInt(b.age);
  });

  const columnStyle = {
    display: "inline-block",
    width: "50%",
    verticalAlign: "top",
    boxSizing: "border-box",
    padding: "0 10px",
  };

  return (
    <div>
      <Polar />
      <div style={{ display: 'flex' }}>
        <div style={{ ...columnStyle, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <PieChart
            id="pie"
            dataSource={genderData}
            palette="Bright"
            title="% Gender"
          >
            <Series
              argumentField="gender"
              valueField="percentage"
            >
              <Label visible={true}>
                <Connector visible={true} width={1} />
              </Label>
            </Series>
            <Size width={500} />
          </PieChart>
        </div>
        <div style={{ ...columnStyle, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <PieChart
            id="pie"
            dataSource={sortedData}
            palette="Bright"
            title="% Ages"
          >
            <Series argumentField="age" valueField="percentage">
              <Label visible={true}>
                <Connector visible={true} width={1} />
              </Label>
            </Series>
            <Size width={500} />
          </PieChart>
        </div>
      </div>
    </div>
  );
};

export default Stats;
