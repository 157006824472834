// components/Dashboard.jsx
import React, { useEffect, useState } from "react";
import { Grid, GridItem, Box, VStack, Heading, Center } from "@chakra-ui/react";
import PieChart from "./PieChart";
//import RadarChart from "./RadarChart";
import data from "../data.json";

const Dashboard = () => {
  const [ageData, setAgeData] = useState([]);
  const [genderData, setGenderData] = useState([]);
  const [radarData, setRadarData] = useState([]);
  const [categories, setCategories] = useState([]);

  const calculateMedians = (items) => {
    const sortedItems = items.sort((a, b) => a - b);
    const half = Math.floor(sortedItems.length / 2);

    if (sortedItems.length % 2) {
      return sortedItems[half];
    }
    return (sortedItems[half - 1] + sortedItems[half]) / 2.0;
  };

  useEffect(() => {
    const ageCategories = ["<35", "35-50", ">50", "prefer not to say"];
    const genderCategories = ["female", "male", "non-binary", "prefer not to say"];

    const ageCounts = Array(ageCategories.length).fill(0);
    const genderCounts = Array(genderCategories.length).fill(0);

    const generalFeedbackMedians = Array(5).fill(0);
    const specificFeedbackMedians = Array(21).fill(0);

    for (const entry of data) {
      const ageIndex = ageCategories.findIndex((age) => age === entry.age);
      const genderIndex = genderCategories.findIndex((gender) => gender === entry.gender);

      if (ageIndex >= 0) {
        ageCounts[ageIndex]++;
      }

      if (genderIndex >= 0) {
        genderCounts[genderIndex]++;
      }

      for (let i = 1; i <= 5; i++) {
        generalFeedbackMedians[i - 1] += entry.general_feedback[i];
      }

      for (let i = 6; i <= 26; i++) {
        specificFeedbackMedians[i - 6] += entry.specific_feedback[i];
      }
    }

    setAgeData(ageCounts);
    setGenderData(genderCounts);

    const medianGeneralFeedback = generalFeedbackMedians.map((total) => total / data.length);
    const medianSpecificFeedback = specificFeedbackMedians.map((total) => total / data.length);

    const radarValues = [
        Math.round(calculateMedians(medianGeneralFeedback)),
        Math.round(calculateMedians(medianSpecificFeedback.slice(0, 3))),
        Math.round(calculateMedians(medianSpecificFeedback.slice(3, 6))),
        Math.round(calculateMedians(medianSpecificFeedback.slice(6, 9))),
        Math.round(calculateMedians(medianSpecificFeedback.slice(9, 12))),
        Math.round(calculateMedians(medianSpecificFeedback.slice(12, 15))),
        Math.round(calculateMedians(medianSpecificFeedback.slice(15, 18))),
        Math.round(calculateMedians(medianSpecificFeedback.slice(18, 21))),
      ].map((value) => Math.min(Math.max(value, 1), 4));
      

    setRadarData(radarValues);
    setCategories([
      "Diversity General",
      "Gender",
      "Sexual Orientation",
      "Religion",
      "Age",
      "Physical/Mental Ability",
      "Social Background",
      "Ethnicity/Nationality",
    ]);
  }, []);

  return (
<Box bg="gray.200" p={5} minHeight="100vh">
  <VStack spacing={5} mb={5} bg="gray.200">
    <Heading as="h1" size="2xl" p={3}>
      Dashboard
    </Heading>
  </VStack>
      <Grid templateColumns="repeat(8, 1fr)" templateRows='repeat(2, 1fr)' gap={4}>
        <GridItem rowSpan={2} colSpan={3}>
          <VStack spacing={6}>
            <Box>
              <Heading as="h2" size="lg">
                Age Distribution
              </Heading>
              <PieChart data={ageData} labels={["<35", "35-50", ">50", "Prefer not to say"]} />
            </Box>
            <Box>
              <Heading as="h2" size="lg">
                Gender Distribution
              </Heading>
              <PieChart data={genderData} labels={["Female", "Male", "Non-binary", "Prefer not to say"]} />
            </Box>
          </VStack>
        </GridItem>
        <GridItem rowSpan={2} colSpan={5} >
          <Box>
          <Center>
  <Heading as="h2" size="lg" style={{ textAlign: "center" }}>
    Radar Chart
  </Heading>
</Center>
           
          </Box>
        </GridItem>
        {/* <GridItem rowSpan={2}>
          <VStack spacing={6}>
            <Box>
              <Heading as="h2" size="lg">
                Criteria List
              </Heading>
              <CriteriaList items={categories} />
            </Box>
          </VStack>
        </GridItem> */}
      </Grid>
    </Box>
  );
};

export default Dashboard;