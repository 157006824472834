import React from "react";
import { Stack, Flex, Text, Box, Image, Button } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import LanguageSelector from "../LanguageSelector";
import logo from "./cgi-logo.png";

function Bar() {
  const navigate = useNavigate();

  const textStyle = {
    fontFamily: "Source Sans Pro, sans-serif",
  };

  return (
    <React.Fragment>
      <Box
        pb="4px"
        w="auto"
        style={{
          boxShadow: " 0px 15px 10px -15px #111",
        }}
      >
        <Flex
          px="3vw"
          align="center"
          justify="space-between"
          width="100%"
          h="73px"
        >
          <Stack isInline>
            <Image
              onClick={() => navigate("/")}
              src={logo}
              alt="CGI Logo"
              css={{ cursor: "pointer" }}
            />
            <Text fontSize={"20px"}>|</Text>
            <Text style={textStyle} fontSize={"20px"}>
              DEI Maturity Assessment{" "}
            </Text>
          </Stack>

          <Stack
            fontWeight={"450"}
            isInline
            align="center"
            justify={"center"}
            spacing="3"
          >
            <Button onClick={() => navigate("/faq")}>FAQ</Button>
            <LanguageSelector />
          </Stack>
        </Flex>
      </Box>
    </React.Fragment>
  );
}

export default Bar;
