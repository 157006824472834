import { Flex, Stack, Text, Button, Center, Textarea } from '@chakra-ui/react'
import React, { useState, useEffect, useContext } from 'react'

// import the stylesheet
// import "react-step-progress/dist/index.css";
// import { Grid, GridItem } from "@chakra-ui/react";
import Sidebar from '../Sidebar'
import Question from '../Question'
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons'
import { Box, useMediaQuery } from '@chakra-ui/react'
import { SimpleGrid } from '@chakra-ui/react'
import { Texts, LanguageContext } from '../../Language'

const textStyle = {
  fontFamily: 'Source Sans Pro, sans-serif',
}

const genderQuestions = {
  abbr: 'GEN',
  title: <Texts tid="GEN" />,
  questions: [
    {
      id: '6',
      question: <Texts tid="SF6" />,
    },
    {
      id: '7',
      question: <Texts tid="SF7" />,
    },
    {
      id: '8',
      question: <Texts tid="SF8" />,
    },
  ],
}

const sexQuestions = {
  abbr: 'SEX',
  title: <Texts tid="SEX" />,
  questions: [
    {
      id: '9',
      question: <Texts tid="SF9" />,
    },
    {
      id: '10',
      question: <Texts tid="SF10" />,
    },
    {
      id: '11',
      question: <Texts tid="SF11" />,
    },
  ],
}

const religionQuestions = {
  abbr: 'REL',
  title: <Texts tid="REL" />,
  questions: [
    {
      id: '12',
      question: <Texts tid="SF12" />,
    },
    {
      id: '13',
      question: <Texts tid="SF13" />,
    },
    {
      id: '14',
      question: <Texts tid="SF14" />,
    },
  ],
}

const ageQuestions = {
  abbr: 'AGE',
  title: <Texts tid="AGE" />,
  questions: [
    {
      id: '15',
      question: <Texts tid="SF15" />,
    },
    {
      id: '16',
      question: <Texts tid="SF16" />,
    },
    {
      id: '17',
      question: <Texts tid="SF17" />,
    },
  ],
}

const pmaQuestions = {
  abbr: 'PMA',
  title: <Texts tid="PMA" />,
  questions: [
    {
      id: '18',
      question: <Texts tid="SF18" />,
    },
    {
      id: '19',
      question: <Texts tid="SF19" />,
    },
    {
      id: '20',
      question: <Texts tid="SF20" />,
    },
  ],
}

const socialQuestions = {
  abbr: 'SOC',
  title: <Texts tid="SOC" />,
  questions: [
    {
      id: '21',
      question: <Texts tid="SF21" />,
    },
    {
      id: '22',
      question: <Texts tid="SF22" />,
    },
    {
      id: '23',
      question: <Texts tid="SF23" />,
    },
  ],
}

const ethinicityQuestions = {
  abbr: 'ETH',
  title: <Texts tid="ETH" />,
  questions: [
    {
      id: '24',
      question: <Texts tid="SF24" />,
    },
    {
      id: '25',
      question: <Texts tid="SF25" />,
    },
    {
      id: '26',
      question: <Texts tid="SF26" />,
    },
  ],
}

const questions = [
  genderQuestions,
  sexQuestions,
  religionQuestions,
  ageQuestions,
  pmaQuestions,
  socialQuestions,
  ethinicityQuestions,
]

function SpecificFeedback({
  onButtonClick,
  setFinalAnswers,
  setFinalFeedbacks,
}) {
  const lanContext = useContext(LanguageContext)
  const dictionary = lanContext.dictionary

  const [theAnswers, setTheAnswers] = useState({})

  const [feedbacks, setFeedbacks] = useState({})
  const handleSetFeedbacks = (cat, feedback) => {
    const temp = { ...feedbacks }
    temp[cat] = feedback
    setFeedbacks(temp)
  }

  function getTextById(id) {
    if (id === 1) {
      return 1
    } else if (id === 2) {
      return 2
    } else if (id === 3) {
      return 3
    } else if (id === 4) {
      return 4
    } else if (id === 0) {
      return 0
    }
  }

  function setAnswer(question, answer) {
    const temp = { ...theAnswers }
    temp[question.id] = getTextById(answer)

    setTheAnswers(temp)
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  function goNext() {
    console.log('here are the answers', theAnswers)
    setFinalAnswers('specific_feedback', theAnswers)
    setFinalFeedbacks(feedbacks)

    onButtonClick('pagethree')
  }
  const [isLargerThanMobile] = useMediaQuery('(min-width: 768px)')

  return (
    <div style={{ display: 'grid', gridTemplateColumns:  '15vw 85vw', gap: '0px' }}>
      <div  > 
          <Box  maxContentWidth="fit-content" > 
            {isLargerThanMobile && (
              <Sidebar
                onButtonClick={onButtonClick}
                pathname={'SpecificFeedback'}
              />
            )}
          </Box>
      </div>
      <div>

      <SimpleGrid  width='auto'>
       

          <Stack  bgGradient="linear(to-r, #E31937, #5236AB)" height={'100%'} >
            <div>
            
             
          
            <Box style={{
                  ...textStyle,
                  fontSize: "18px",
                  fontWeight: "600",
                  color: "white",
                  textAlign: "center", 
                  marginTop: "2%"
              
                }}
          >
            <Texts tid="Spec1" />

          </Box>
          <Box
                style={textStyle}
                    fontSize="16px"
                    fontWeight={'600'}                   
                    textAlign="center"                                       
                    color={'gray.300'}
          >
                <Texts tid="Spec2" />
          </Box>
          <Box
                style={textStyle}
                    fontSize="16px"
                    fontWeight={'600'}                   
                    textAlign="center"                                       
                    color={'gray.300'}
                    marginBottom={'2%'}
          >
                <Texts tid="Spec3" />
          </Box>

              
           

          <Stack style={textStyle} padding="5" fontSize="16px" spacing="10" >
                {questions.map(qSet => (
                  <>
                    <Center>
                      <Text fontSize={'2xl'} color={'gray.300'}>
                        {qSet.title}
                      </Text>
                    </Center>
                    {qSet.questions.map(q => (
                      <Question question={q} setAnswer={setAnswer} />
                    ))}
                    <Center>
                      <Textarea
                        onChange={e =>
                          handleSetFeedbacks(
                            dictionary[qSet.abbr],
                            e.target.value
                          )
                        }
                        width={'770px'}
                        backgroundColor="white"
                        placeholder={`Here you can give feedback for the ${dictionary[
                          qSet.abbr
                        ].toLowerCase()} questions`}
                      />
                    </Center>
                  </>
                ))}
              </Stack>
            </div>
            <Flex
              bottom="0"
              width="100%"
              px="5vw"
              border="1px"
              borderColor="rgba(0, 0, 0, 0)"
              py="10px"
              justify={'space-between'}
              bg="rgba(0, 0, 0, 0)"
              verticalAlign={'center'}
            >
              <Button
                leftIcon={<ChevronLeftIcon />}
                colorScheme="teal"
                onClick={() => onButtonClick('pageone')}
                variant="solid"
                borderRadius={'16px'}
                bgColor={'#9E83F5'}
                style={textStyle}
              >
                <Texts tid="ButtonPrev" />
              </Button>

              <Button
                rightIcon={<ChevronRightIcon />}
                colorScheme="Teal"
                onClick={() => goNext()}
                variant="solid"
                style={textStyle}
                borderRadius={'16px'}
                bgColor={'#9E83F5'}
              >
                <Texts tid="ButtonNext" />
              </Button>

              {/* <Button
            colorScheme={"blue"}
            onClick={goNext}
          >
            Next
          </Button> */}
            </Flex>
          </Stack>
    
      </SimpleGrid>

      </div>
    </div>
  )
}

export default SpecificFeedback
